import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, OnDestroy, ViewChild, inject, input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TrackInputFocusDirective } from '@directives/track-input-focus.directive';
import { SearchFormControls } from '@enums/form-controls.enum';
import { TopBarSearchType } from '@enums/top-bar-search-type.enum';
import { SearchForm } from '@interfaces/forms/search-form.interface';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from 'angular-svg-icon';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TopBarSearchService } from './top-bar-search.service';

/**
 * Array of PrimeNG modules used in the top-bar-search component.
 */
const PRIME_NG_MODULES = [InputTextModule, OverlayPanelModule, IconFieldModule, InputIconModule];

/**
 * Component for the top bar search functionality.
 */
@Component({
  selector: 'app-top-bar-search',
  standalone: true,
  imports: [
    PRIME_NG_MODULES,
    ReactiveFormsModule,
    TrackInputFocusDirective,
    SvgIconComponent,
    TranslateModule,
    NgClass,
  ],
  templateUrl: './top-bar-search.component.html',
  styleUrl: './top-bar-search.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TopBarSearchService],
})
export class TopBarSearchComponent implements OnDestroy {
  /**
   * The input element used for searching.
   */
  @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;
  /**
   * Service for handling top bar search functionality.
   */
  readonly topBarSearchService = inject(TopBarSearchService);

  /**
   * Controls for the search form.
   */
  SearchFormControls = SearchFormControls;

  /**
   * The type of the top bar search.
   */
  type = input<TopBarSearchType>(TopBarSearchType.RESTAURANTS);

  /**
   * Represents the type of search in the top bar.
   */
  TopBarSearchType = TopBarSearchType;

  /**
   * Gets the form group for the search form.
   * @returns The form group for the search form.
   */
  get form(): FormGroup<SearchForm> {
    return this.topBarSearchService.form;
  }

  constructor() {
    this.topBarSearchService.listenFormChange();
    this.topBarSearchService.listenEventBus();
    this.getLastSearchItems();
  }

  /**
   * Retrieves the last search items for the current user.
   */
  getLastSearchItems(): void {
    const userId = this.topBarSearchService.getUserId();
    if (!userId) return;
    this.topBarSearchService.dbGetUserLastSearch(userId);
  }

  /**
   * Clears the search form control and sets focus to the search input element.
   */
  clearAndFocus(): void {
    this.form.get(SearchFormControls.SEARCH)?.setValue(null);
    this.searchInput?.nativeElement.focus();
  }

  /**
   * Lifecycle hook that is called when the component is destroyed.
   * Unsubscribes from the event bus in the `topBarSearchService`.
   */
  ngOnDestroy(): void {
    this.topBarSearchService.unsubscribeEventBus();
  }
}
