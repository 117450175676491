import { Injectable, inject, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { EventDataType } from '@enums/event-data-type.enum';
import { SearchFormControls } from '@enums/form-controls.enum';
import { untilDestroyed } from '@functions/until-destroyed.function';
import { SearchForm } from '@interfaces/forms/search-form.interface';
import { EventBusService } from '@services/event-bus.service';
import { StorageService } from '@services/storage.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { Subscription, debounceTime } from 'rxjs';
import { DatabaseTables } from 'src/app/database/enums/database-tables.enum';
import { DBLastSearch } from 'src/app/database/interfaces/last-search.interface';

/**
 * The debounce time (in milliseconds) used for filtering.
 */
const FILTERS_DEBAUNCE = 800;
/**
 * The maximum number of last search items to display.
 */
const LAST_SEARCH_ITEMS_LIMIT = 3;

/**
 * Service responsible for managing the top bar search functionality.
 */
@Injectable()
export class TopBarSearchService {
  /**
   * A utility method that creates a subscription that will automatically unsubscribe
   * when the component or service is destroyed.
   */
  private readonly untilDestroyed = untilDestroyed();
  /**
   * Service responsible for handling events related to the event bus.
   */
  private readonly eventBusService = inject(EventBusService);
  /**
   * Service for interacting with the database.
   */
  private readonly dbService = inject(NgxIndexedDBService);
  /**
   * Service for managing storage operations.
   */
  private readonly storageService = inject(StorageService);
  /**
   * The router instance used for navigation.
   */
  private readonly router = inject(Router);

  /**
   * Represents the form used for searching in the top bar.
   */
  readonly form: FormGroup<SearchForm> = this.initializeSearchForm();
  /**
   * Represents a subscription to the event bus.
   */
  private eventBusSub$: Subscription = new Subscription();

  /**
   * Represents the last search items.
   */
  lastSearchItems = signal<DBLastSearch[]>([]);

  /**
   * Initializes the search form with the query parameter value from the current URL.
   * @returns A FormGroup instance representing the search form.
   */
  private initializeSearchForm(): FormGroup<SearchForm> {
    const queryParams = this.router.parseUrl(this.router.url).queryParams;
    const search = queryParams[SearchFormControls.SEARCH];

    return new FormGroup<SearchForm>({
      [SearchFormControls.SEARCH]: new FormControl(search),
    });
  }

  /**
   * Retrieves the user ID from the storage service.
   * @returns The user ID if available, otherwise undefined.
   */
  public getUserId(): number | undefined {
    return this.storageService.getUser()?.id;
  }

  /**
   * Listens for changes in the form and performs actions based on the changes.
   */
  public listenFormChange(): void {
    this.form
      .get(SearchFormControls.SEARCH)
      ?.valueChanges.pipe(this.untilDestroyed(), debounceTime(FILTERS_DEBAUNCE))
      .subscribe((value: string | null) => {
        this.emitSearchValue(value?.length ? value : null);
        this.handleLastItemsSearch(value);
      });
  }

  /**
   * Sets the value of the search form control to the specified search string and emits the search value.
   * @param search - The search string to set.
   */
  public selectLastSearchItem(search: string): void {
    this.form.get(SearchFormControls.SEARCH)?.setValue(search, { emitEvent: false });
    this.emitSearchValue(search);
  }

  /**
   * Resets the form.
   */
  public reset(): void {
    this.form.reset();
  }

  /**
   * Listens to the event bus for the CLEAR_FILTERS event and updates the search form accordingly.
   */
  public listenEventBus(): void {
    this.eventBusSub$ = this.eventBusService.on(EventDataType.CLEAR_FILTERS, (value: string | null) => {
      if (!value) {
        this.form.get(SearchFormControls.SEARCH)?.setValue(null, { emitEvent: false });
      }
    });
  }

  /**
   * Unsubscribes from the event bus subscription, if it exists.
   */
  public unsubscribeEventBus(): void {
    this.eventBusSub$.unsubscribe();
  }

  /**
   * Emits the search value to the event bus service.
   * @param value - The search value to emit.
   */
  private emitSearchValue(value: string | null): void {
    this.eventBusService.emit({ name: EventDataType.SEARCH, value: value?.length ? value : null });
  }

  /**
   * Handles the last items search.
   *
   * @param value - The search value.
   */
  private handleLastItemsSearch(value: string | null): void {
    const userId = this.getUserId();
    if (value?.length && userId) {
      if (this.lastSearchItems().length >= LAST_SEARCH_ITEMS_LIMIT) {
        this.dbDeleteValue();
      }
      this.dbAddValue(value, userId);
    }
  }

  /**
   * Deletes the last search value from the database.
   */
  private dbDeleteValue(): void {
    const last = this.lastSearchItems()[this.lastSearchItems().length - 1];
    this.dbService
      .delete<DBLastSearch>(DatabaseTables.LAST_SEARCH, last?.id)
      .pipe(this.untilDestroyed())
      .subscribe((items: DBLastSearch[]) => {
        this.lastSearchItems.set(items.reverse());
      });
  }

  /**
   * Adds a value to the database for the specified search and user ID.
   * @param search - The search value to add.
   * @param userId - The ID of the user.
   */
  private dbAddValue(search: string, userId: number): void {
    this.dbService
      .add(DatabaseTables.LAST_SEARCH, { search, userId })
      .pipe(this.untilDestroyed())
      .subscribe(() => this.dbGetUserLastSearch(userId));
  }

  /**
   * Retrieves the last search items for a given user from the database.
   * @param userId - The ID of the user.
   */
  public dbGetUserLastSearch(userId: number): void {
    this.dbService
      .getAllByIndex<DBLastSearch>(DatabaseTables.LAST_SEARCH, 'userId', IDBKeyRange.only(userId))
      .pipe(this.untilDestroyed())
      .subscribe((items: DBLastSearch[]) => this.lastSearchItems.set(items.reverse()));
  }
}
