<div class="top-bar-search" [ngClass]="'top-bar-search-' + type()">
  <form [formGroup]="form" #formRef>
    <p-iconField class="icon-field-both" [trackInputFocus]="searchInput">
      <p-inputIcon class="icon-field-both-icon icon-field-both-left">
        <svg-icon src="assets/icons/pencil.svg"></svg-icon>
      </p-inputIcon>
      <input
        #searchInput
        autocomplete="off"
        id="search-input"
        placeholder="{{ 'Placeholders.Search' | translate }}"
        type="text"
        pInputText
        (focus)="
          topBarSearchService.lastSearchItems().length
            ? type() === TopBarSearchType.DISHES
              ? null
              : lastSearchPanel?.show($event)
            : null
        "
        [formControlName]="SearchFormControls.SEARCH" />
      <p-inputIcon
        class="icon-field-both-icon icon-field-both-right pointer"
        (click)="searchInput.value ? topBarSearchService.reset() : null">
        @if (searchInput.value) {
          <svg-icon class="clear-input-icon" src="assets/icons/close-circle.svg"></svg-icon>
        } @else {
          <svg-icon src="assets/icons/search.svg"></svg-icon>
        }
      </p-inputIcon>
    </p-iconField>
  </form>
</div>
<div class="top-bar-search-overlay">
  <p-overlayPanel
    #lastSearchPanel
    styleClass="top-bar-search-overlay-panel"
    [appendTo]="formRef"
    [showCloseIcon]="true"
    [dismissable]="false"
    showTransitionOptions="0s"
    hideTransitionOptions="0s">
    <ng-template pTemplate="content">
      <div class="last-search">
        <div class="last-search-title">{{ 'RestaurantsPage.Last search title' | translate }}</div>
        <div class="last-search-list">
          @for (item of topBarSearchService.lastSearchItems(); track $index) {
            <div
              class="last-search-list-item"
              (click)="topBarSearchService.selectLastSearchItem(item.search); lastSearchPanel.hide()">
              {{ item.search }}
            </div>
          }
        </div>
      </div>
    </ng-template>
  </p-overlayPanel>
</div>
